#root .App {
  min-height: 100vh;
  overflow-x: auto; }
  #root .App.role-user {
    padding-top: 80px;
    background: linear-gradient(to bottom, #0070ba 0%, #34c9e1 100%); }
  #root .App.role-coach, #root .App.role-prescriber {
    padding-top: 80px;
    background-color: #2c5cdb; }
  #root .App.role-manager {
    padding-top: 80px;
    background-color: #009fe3; }
  #root .App.role-admin {
    padding-top: 80px;
    background-color: #00c6a6; }
  #root .App.new-design {
    padding-top: 0 !important;
    background-color: white !important; }

.modal-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 1100; }

.modal-content {
  position: relative;
  display: inline-block;
  margin-top: 85px;
  max-width: 90vw;
  max-height: calc(100vh - 95px);
  overflow-y: auto;
  padding: 16px;
  background: radial-gradient(ellipse at center, #36cce1 0%, #0070ba 100%);
  border: thin solid #fff;
  border-radius: 10px; }
  .modal-content.bg-pink {
    background: #e6007e; }
